<template>
  <div>
    <static-fullscreen-card v-show="!idItemShow">
      <template v-slot:header></template>
      <template v-slot:actions>
        <v-btn color="primary" @click="$refs.table.updateData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title></v-btn>
        <v-btn v-if="false" color="primary" @click="createNew()" title="Создать элемент"><btn-title icon="far fa-plus-square">Создать</btn-title></v-btn>
        <v-btn v-if="$root.profile.id == 13" @click="onClick">test</v-btn>
      </template>
      <portal to="v-main">
        <s-edit-form v-model="showEditDialog" v-if="showEditDialog" :api="url" :m="m" :config="configHead" :title="configHead.title" :id="idEdit" />
      </portal>
      <a-table-f-api
        ref="table"
        :api="url"
        :model="model"
        :selectedRows="selectedRows"
        :useQuery="true"
        :defaults="defaults"
        @click="
          counter += 1;
          onDetectClick($event);
        "
      />
    </static-fullscreen-card>
    <ViewItem v-if="idItemShow" :idShow="idItemShow" @close="itemShowClose($event)" />
  </div>
</template>

<script>
import { getAccess, doubleClickDetect, popupMenu } from "@/components/mixings";
export default {
  mixins: [getAccess, doubleClickDetect, popupMenu],
  components: {
    ViewItem: () => import("./views/payOrderView"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      idItemShow: 0,
      showDetail: false,
      title: "",
      m: this.$store.getters["config/get"].models.payOrder, 

      defaults: {
        // filters: { status: 1 },
        sort: { key: "id", order: "DESC" },
        paramName: "payOrder",
      },
    };
  },
  created() {
    this.$root.title = this.m.title;
  },
  computed: {
    configHead() {
      return this.m.config.default || {};
    },
    url() {
      return this.m.api;
    },
    model() {
      let model = JSON.parse(JSON.stringify(this.m.list));
      model.forEach((el) => {
        if (el?.sourceList) {
          el.options = this.m[el.sourceList];
        }
      });
      return model;
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
    showDetail(v) {
      if (!v) {
        this.idItemShow = 0;
        this.$emit("onClose");
      }
    },
  },
  methods: {
    async onClick() {
      // await this.$axios.get("/mechti/design-projects/site", { params: { filters: {}, pager: { limit: 50, page: 0, count: 0 } } });
      await this.$axios.get("/mechti/objects/site/object/15", { params: { filters: {}, pager: { limit: 50, page: 0, count: 0 } } });
    },
    onClickRow(d) {
      // this.$router.push({ path: "/modal" });
      //this.idItemShow = d.row.id;
      //this.showDetail = true;
      //this.showEditDialogFun(d.row.id);
      this.$router.push({ name: this.m.routeName, params: { id: d.row.id } });
    },
    onSingleClick(d) {
      if (d.field?.isMenu) {
        this.showPopupMenu(d.field.name, d.row, d.event);
      }
    },
    onDoubleClick(d) {
      this.idItemShow = d.row.id;
      return;
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
    itemShowClose() {
      this.loading = true;
      this.$refs.table.updateRowData(this.idItemShow);
      this.loading = !true;
      this.idItemShow = 0;
    },
  },
};
</script>
